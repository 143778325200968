import styles from './Socials.module.scss';
import { ReactComponent as VkIcon } from '../../../assets/images/icons/icon-vk.svg';
import { ReactComponent as InstIcon } from '../../../assets/images/icons/icon-telegram.svg';

export const socialsArr = [
  { id: 1, icon: InstIcon, label: 'Телеграм', link: '' },
  { id: 2, icon: VkIcon, label: 'Вконтакте', link: '' },
]

const Socials = ({ className }) => {
  return (
    <ul className={`${styles.list} ${className}`}>
      {socialsArr.map(item => (
        <li className={styles.item} key={item.id}>
          <a href={item.link} target="_blank" rel="noopener noreferrer">
            <item.icon />
            <span className="visually-hidden">{item.label}</span>
          </a>
        </li>
      ))}
    </ul>
  )
}

export default Socials;
