import * as React from "react";

export function IconArrowsRight(props) {
  return (
    <svg
      width={85}
      height={54}
      fill="none"
      viewBox="0 0 85 54"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.463 0H0l27.105 26.975L0 53.963.037 54h21.39l27.14-27.025L21.464 0z"
        fill="#ED1C24"
      />
      <path
        d="M57.908 0H36.445l27.092 26.975-27.092 26.988.037.037H57.87L85 26.975 57.907 0z"
        fill="#ED1C24"
      />
    </svg>
  );
}
