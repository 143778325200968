import { useTranslation } from 'react-i18next';

import logoMl from '@/assets/images/logo-ml-white.svg';
import mainLogo, { ReactComponent as LogoLiga } from '@/assets/images/logo-liga-white.svg';
import Container from '@/components/app/Container/Container';

import Socials from './Socials/Socials';
import styles from './Footer.module.scss';
import { ReactComponent as LogoLigaEn } from '@/assets/images/logo-liga-white-en.svg';


const Footer = () => {
  const { t, i18n } = useTranslation();
  const dir = i18n.language === 'ar' ? 'rtl' : 'ltr';

  return (
    <footer className={styles.footer}>
      <Container>
        <div className={styles.footerWrapper}>
          <div className={styles.development} dir={dir}>
            <img src={logoMl} width="127" height="26" alt="Логотип Marketing League"/>
            <p className={styles.developmentText}>{t("footer.incarnation")}</p>
          </div>
          <div className={styles.logo}>
            {
              i18n.language === 'ru' ? (
                <LogoLiga className={styles.mainLogo} />
              ) : (
                <LogoLigaEn className={styles.mainLogo} />
              )
            }
            {/*<img className={styles.mainLogo} src={mainLogo} width="72" height="46" alt="Логотип Лиги Героев"/>*/}
          </div>
          <div className={styles.socials}>
            <Socials />
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer;
