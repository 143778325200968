import { useTranslation } from 'react-i18next';

import { IconArrowsRight } from '@/components/ui/svg';

import styles from './Statistics.module.scss';


function Statistics() {
  const { t, i18n } = useTranslation();
  const dir = i18n.language === 'ar' ? 'rtl' : 'ltr';

  return (
    <section className={styles.statistics} dir={dir}>
        <h2 className={styles.title}>{t('statistics.title')}</h2>

        <div className={styles.texts}>
          <p className={styles.text1}>
            {t('statistics.text1')}
          </p>
          <p>
            {t('statistics.text2')}
          </p>
        </div>

        <ul className={styles.list}>
          <li className={styles.item}>
            <IconArrowsRight className={styles.itemIcon} />
            <div>
              <p className={styles.itemValue}>{t('statistics.item1.value')}</p>
              <p className={styles.itemLabel}>{t('statistics.item1.label')}</p>
            </div>
          </li>

          <li className={styles.item}>
            <IconArrowsRight className={styles.itemIcon} />
            <div>
              <p className={styles.itemValue}>{t('statistics.item2.value')}</p>
              <p className={styles.itemLabel}>{t('statistics.item2.label')}</p>
            </div>
          </li>

          <li className={styles.item}>
            <IconArrowsRight className={styles.itemIcon} />
            <div>
              <p className={styles.itemValue}>{t('statistics.item3.value')}</p>
              <p className={styles.itemLabel}>{t('statistics.item3.label')}</p>
            </div>
          </li>

          <li className={styles.item}>
            <IconArrowsRight className={styles.itemIcon} />
            <div>
              <p className={styles.itemValue}>{t('statistics.item4.value')}</p>
              <p className={styles.itemLabel}>{t('statistics.item4.label')}</p>
            </div>
          </li>
        </ul>
      </section>
  );
}

export default Statistics;
