import * as React from "react";

export function IconLocation(props) {
  return (
    <svg
      width={26}
      height={37}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13 0C5.755 0 0 5.733 0 12.949 0 22.568 13 37 13 37s13-14.428 13-24.047C26 5.737 20.245 0 13 0zm0 17.577c-2.598 0-4.642-2.036-4.642-4.624 0-2.588 2.044-4.624 4.642-4.624 2.598 0 4.642 2.036 4.642 4.624 0 2.588-2.044 4.624-4.642 4.624z"
        fill="#ED1C24"
      />
    </svg>
  );
}
