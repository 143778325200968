import 'swiper/css';
import 'swiper/css/navigation';
import { useState } from 'react';
import { Autoplay } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';

import photo1 from '@/assets/images/gallery/gallery1.jpg';
import photo2 from '@/assets/images/gallery/gallery2.jpg';
import photo3 from '@/assets/images/gallery/gallery3.jpg';
import photo4 from '@/assets/images/gallery/gallery4.jpg';
import photo5 from '@/assets/images/gallery/gallery5.jpg';
import photo1Webp from '@/assets/images/webp/gallery/gallery1.webp';
import photo2Webp from '@/assets/images/webp/gallery/gallery2.webp';
import photo3Webp from '@/assets/images/webp/gallery/gallery3.webp';
import photo4Webp from '@/assets/images/webp/gallery/gallery4.webp';
import photo5Webp from '@/assets/images/webp/gallery/gallery5.webp';
import ResponsiveImage from '../ui/ResponsiveImage/ResponsiveImage';

import styles from './Gallery.module.scss';


const slides = [
  {
    id: 1,
    img: photo1,
    imgWebp: photo1Webp,
  },
  {
    id: 2,
    img: photo2,
    imgWebp: photo2Webp,
  },
  {
    id: 3,
    img: photo3,
    imgWebp: photo3Webp,
  },
  {
    id: 4,
    img: photo4,
    imgWebp: photo4Webp,
  },
  {
    id: 5,
    img: photo5,
    imgWebp: photo5Webp,
  },
];

function Gallery() {
  const { t } = useTranslation();
  const [swiper, setSwiper] = useState(null);

  return (
    <section className={styles.gallery}>
      <div className={styles.sliderWrapper}>
          <Swiper
            spaceBetween={20}
            slidesPerView="1"
            loop
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            className={styles.slider}
            onBeforeInit={(swiper) => setSwiper(swiper)}
            modules={[Autoplay]}
          >
            {slides.map((item) => (
              <SwiperSlide className={styles.slide} key={item.id}>
                <ResponsiveImage
                  classNameImage={styles.sliderImg}
                  src={item.img}
                  srcWebp={item.imgWebp}
                  alt=""
                />
              </SwiperSlide>
            ))}
          </Swiper>

          <div className={styles.navigation}>
            <button className={`${styles.navBtn} ${styles.navPrev}`} onClick={() => swiper.slidePrev()}>
              <span className="visually-hidden">Предыдущий слайд</span>
            </button>
            <button className={`${styles.navBtn} ${styles.navNext}`} onClick={() => swiper.slideNext()}>
              <span className="visually-hidden">Следующий слайд</span>
            </button>
          </div>
        </div>

      <a className={styles.anchor} href="#formats">{t('anchorLink')}</a>
    </section>
  );
}

export default Gallery;
