import { useTranslation } from 'react-i18next';

import img1 from '@/assets/images/about1.jpg';
import img2 from '@/assets/images/about2.jpg';
import { IconArrowsRight } from '@/components/ui/svg';
import img1Webp from '@/assets/images/webp/about2.webp';
import img2Webp from '@/assets/images/webp/about2.webp';
import Container from '@/components/app/Container/Container';
import ResponsiveImage from '@/components/ui/ResponsiveImage/ResponsiveImage';

import styles from './About.module.scss';


function About() {
  const { t, i18n } = useTranslation();
  const dir = i18n.language === 'ar' ? 'rtl' : 'ltr';

  return (
    <section className={styles.about} id="about">
      <Container>
        <div className={styles.top} dir={dir}>
          <div className={styles.titleWrap}>
            <IconArrowsRight className={styles.titleIcon} />
            <h2 className={styles.title}>{t('about.title')}</h2>
          </div>

          <div className={styles.textWrap}>
            <p className={styles.text1}>
              {t('about.text1')}
            </p>
            <p>
              {t('about.text2')}
            </p>
          </div>
        </div>

        <div className={styles.images}>
          <ResponsiveImage
            classNameImage={styles.img}
            src={img1}
            srcWebp={img1Webp}
            alt=""
          />
          <ResponsiveImage
            classNameImage={styles.img}
            src={img2}
            srcWebp={img2Webp}
            alt=""
          />
        </div>
      </Container>
    </section>
  );
}

export default About;
