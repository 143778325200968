import * as React from "react";

export function IconTime(props) {
  return (
    <svg
      width={37}
      height={37}
      fill="none"
      viewBox="0 0 37 37"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.5 0C8.29 0 0 8.29 0 18.5S8.29 37 18.5 37 37 28.71 37 18.5 28.71 0 18.5 0zm8.39 21.027h-9.25a1.49 1.49 0 01-1.516-1.516V8.896c0-.86.657-1.516 1.517-1.516.859 0 1.516.657 1.516 1.516v9.098h7.784c.86 0 1.517.658 1.517 1.517-.05.809-.708 1.516-1.567 1.516z"
        fill="#ED1C24"
      />
    </svg>
  );
}
