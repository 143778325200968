import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { AlShaqab, IconLocation, IconTime } from '@/components/ui/svg';
import { ReactComponent as LogoLiga } from '@/assets/images/logo-liga-white.svg';

import styles from './TopPage.module.scss';


function TopPage() {
  const { t, i18n } = useTranslation();
  const isAr = i18n.language === 'ar';
  const dir = isAr ? 'rtl' : 'ltr';
  const isTablet = useMediaQuery({ query: '(max-width: 991px)' });

  return (
    <section className={styles.topPage}>

      {(isTablet && !isAr) && (
        <LogoLiga className={styles.logoLiga} />
      )}

      {isAr && (
        <p className={styles.ligaNameAr}>{t('topPage.ligaName')}</p>
      )}

      <AlShaqab className={styles.logo} />

      <div className={styles.content}>
        {!isTablet && (
          <p className={styles.item} dir={dir}>
            <IconLocation className={styles.itemIcon} />
            <span>{t('topPage.place')}</span>
          </p>
        )}

        <a href="#formats" className={styles.button}>{t('anchorLink')}</a>

        <p className={styles.item}>
          <span>{t('topPage.date')}</span>
          <IconTime className={styles.itemIcon} />
        </p>
      </div>
    </section>
  );
}

export default TopPage;
