import { useLayoutEffect } from 'react';
import MainScreen from '@/pages/MainScreen/MainScreen';
import i18n from '@/i18n';


function App() {
  useLayoutEffect(() => {
    document.documentElement.lang = i18n.language;
  }, []);

  return (
    <>
      <MainScreen />
    </>
  );
}

export default App;
