import { useTranslation } from 'react-i18next';

import { IconArrowsRight } from '@/components/ui/svg';

import styles from './Formats.module.scss';


function Formats() {
  const { t, i18n } = useTranslation();
  const dir = i18n.language === 'ar' ? 'rtl' : 'ltr';

  return (
    <section className={styles.formats} id="program" dir={dir}>
      <h2 className={styles.title}>{t('formats.title')}</h2>

      <ul className={styles.list}>
        <li className={styles.item}>
          <IconArrowsRight className={styles.itemIcon} />
          <div>
            <p className={styles.itemValue}>{t('formats.item1.value')}</p>
            <p className={styles.itemLabel}>{t('formats.item1.label')}</p>
          </div>
        </li>
        <li className={styles.item}>
          <IconArrowsRight className={styles.itemIcon} />
          <div>
            <p className={styles.itemValue}>{t('formats.item2.value')}</p>
            <p className={styles.itemLabel}>{t('formats.item2.label')}</p>
          </div>
        </li>
      </ul>

      <ul className={styles.formatsContainer} id="formats">
        <li className={styles.format}>
          <div className={styles.formatNameWrap}>
            <p className={styles.formatName}>{t('formats.list.item1.name')}</p>
            <span className={styles.formatNameBg} />
          </div>
          <p className={styles.formatDescription}>
            <span>{t('formats.list.item1.description')}</span>
          </p>
        </li>

        <li className={styles.format}>
          <div className={styles.formatNameWrap}>
            <p className={styles.formatName}>{t('formats.list.item2.name')}</p>
            <span className={styles.formatNameBg} />
          </div>
          <p className={styles.formatDescription}>
            <span>{t('formats.list.item2.description')}</span>
          </p>
        </li>

        <li className={styles.format}>
          <div className={styles.formatNameWrap}>
            <p className={styles.formatName}>{t('formats.list.item3.name')}</p>
            <span className={styles.formatNameBg} />
          </div>
          <p className={styles.formatDescription}>
            <span>{t('formats.list.item3.description')}</span>
          </p>
        </li>
      </ul>
    </section>
  );
}

export default Formats;
