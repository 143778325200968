import i18next from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import HiddenScroll from '@/components/app/HideScroll';
import { ReactComponent as LogoLiga } from '@/assets/images/logo-liga-white.svg';
import { ReactComponent as LogoLigaEn } from '@/assets/images/logo-liga-white-en.svg';

import styles from './Header.module.scss';


const menuItems = [
  {
    label: 'item1',
    link: '#about',
  },
  {
    label: 'item2',
    link: '#program',
  },
  {
    label: 'item3',
    link: '#formats',
  },
];

function NavList({ t, navClass, onClick }) {
  return (
    <nav className={`${styles.nav} ${navClass}`}>
      <ul className={styles.list}>
        {menuItems.map((item) => (
          <li onClick={onClick} key={item.label}>
            <a className={styles.link} href={item.link}>{t(`header.${item.label}`)}</a>
          </li>
        ))}
      </ul>
    </nav>
  );
}

function Header() {
  const isTablet = useMediaQuery({ query: '(max-width: 991px)' });
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const { i18n, t } = useTranslation();
  const lang = i18n?.resolvedLanguage;

  const changeLang = (evt) => {
    const { value } = evt.target;

    i18next.changeLanguage(value);
    document.documentElement.lang = i18n.language;
  };

  const handleClickLink = () => {
    setIsOpenMenu(false)
  };

  return (
    <header className={styles.header}>
      <div className={styles.langWrap}>
        <input className={`${styles.inputLang} visually-hidden`}
               type="radio"
               value="ru"
               name="language"
               id="rusLang"
               onChange={changeLang}
               checked={lang === 'ru'}
        />
        <label htmlFor="rusLang" className={styles.headerLabel}>Рус</label>
        <input className={`${styles.inputLang} visually-hidden`}
               type="radio"
               value="en"
               name="language"
               id="engLang"
               onChange={changeLang}
               checked={lang === 'en'}
        />
        <label htmlFor="engLang" className={styles.headerLabel}>Eng</label>
        <input className={`${styles.inputLang} visually-hidden`}
               type="radio"
               value="ar"
               name="language"
               id="arLang"
               onChange={changeLang}
               checked={lang === 'ar'}
        />
        <label htmlFor="arLang" className={styles.headerLabel}>عرب</label>
      </div>

      <NavList t={t} navClass={styles.navDesktop} />

      {!isTablet && (
        i18n.language === 'ru' ? (
          <LogoLiga className={styles.logo} />
        ) : (
          <LogoLigaEn className={styles.logo} />
        )
      )}

      {isTablet && (
        // <button className={styles.toggle} type="button">
        //   <MenuToggle />
        // </button>

        <div className={styles.navContainer}>
          <input
            className={`${styles.checkbox} visually-hidden`}
            onChange={() => setIsOpenMenu((prev) => !prev)}
            type="checkbox"
            id="menu-checkbox"
            checked={isOpenMenu}
          />
          <label className={styles.hamburgerLines} htmlFor="menu-checkbox">
            <span className={`${styles.line} ${styles.line1}`} />
            <span className={`${styles.line} ${styles.line2}`} />
            <span className={`${styles.line} ${styles.line3}`} />
          </label>
        </div>
      )}

      {(isTablet && isOpenMenu) && (
        <div className={styles.navModal}>
          <NavList t={t} navClass={styles.navMobile} onClick={handleClickLink} />

          {/*<a className={`${styles.link} ${styles.linkUser} ${styles.linkUserMobile}`} href="https://heroleague.ru/myevents">Личный кабинет</a>*/}

          <HiddenScroll />
        </div>
      )}
    </header>
  );
}

export default Header;
