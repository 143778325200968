import Marquee from 'react-double-marquee';
import { useTranslation } from 'react-i18next';

import About from '@/components/About/About';
import Footer from '@/components/Footer/Footer';
import Header from '@/components/Header/Header';
import TopPage from '@/components/TopPage/TopPage';
import Formats from '@/components/Formats/Formats';
import Gallery from '@/components/Gallery/Gallery';
import { IconArrowsRight } from '@/components/ui/svg';
import Statistics from '@/components/Statistics/Statistics';

import styles from './MainScreen.module.scss';


function MainScreen() {
  const { t, i18n } = useTranslation();
  const dir = i18n.language === 'ar' ? 'rtl' : 'ltr';

  return (
    <>
      <Header />
      <TopPage />
      <About />
      <div className={styles.bgMiddle}>
        <Statistics />
        <Formats />
      </div>
      <div className={styles.linesWrap}>
        <div className={styles.marqueWrap}>
          <Marquee direction={"left"}>
            <span className={styles.lineText} dir={dir}>
              {t('lineText')} <IconArrowsRight className={styles.iconArrows} /> {t('lineText')} <IconArrowsRight className={styles.iconArrows} /> {t('lineText')} <IconArrowsRight className={styles.iconArrows} /> {t('lineText')} <IconArrowsRight className={styles.iconArrows} /> {t('lineText')} <IconArrowsRight className={styles.iconArrows} />
            </span>
          </Marquee>
        </div>
      </div>
      <div className={styles.bgBottom}>
        <Gallery />
        <Footer />
      </div>
    </>
  );
}

export default MainScreen;
